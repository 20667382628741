
import React from "react";
import { useEffect, useState } from "react";
import { SocialIcon } from 'react-social-icons'
import axios from 'axios'
import Localbase from 'localbase'
import { useNavigate } from "react-router-dom";
import logo from '../img/logo-nobg.png'
import './home.css'
import CircleButton from './CircleButton';
import InfoBox from './InfoBox';
import CourseBox from './CourseBox';
import PaymentAndSocials from './PaymentAndSocials';
import ReviewCard from './ReviewCard';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import strategy from '../img/icons/growth.png'
import setup from '../img/icons/setting.png'
import discord from '../img/icons/discord.png'
import call from '../img/icons/call.png'
import { Grid, Container } from '@mui/material';
import one from '../img/review/one.png'
import two from '../img/review/two.png'
import four from '../img/review/four.png'
import five from '../img/review/five.png'
import six from '../img/review/six.png'
import seven from '../img/review/seven.png'
import eight from '../img/review/eight.png'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import face from '../img/face.JPG'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import WindowIcon from '@mui/icons-material/Window';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import iOSIcon from '@mui/icons-material/PhoneIphone';
function Home(props) {
  const navigate = useNavigate();
  const reviews = [
    {
      image: one,
      reviewer: 'ActualRice',
    },
    {
      image: two,
      reviewer: 'LiquidityHunter',
    },
    {
      image: four,
      reviewer: 'Primo',
    },
    {
      image: five,
      reviewer: 'SashimiScalping',
    },
    {
      image: six,
      reviewer: 'ItachiFX',
    },
    {
      image: eight,
      reviewer: '_mr_trader_',
    }
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };
  const courses = [
    {
      title: 'STANDARD MENTORSHIP',
      price: 99,
      features: [
        { name: 'Discord community', available: true },
        { name: 'Full course SMC', available: true },
        { name: 'Synthetic Trading Course', available: true },
        { name: 'Scalping Mastery', available: true },
        { name: 'Scalping Mastery PDF', available: false },
        { name: 'Weekly zoom calls', available: true },
        { name: 'HTF setups', available: true },
        { name: 'daily outlook', available: false },
        { name: 'Mentorship', available: true },
        { name: 'Live Teaching', available: false }
      ]
    },
    {
      title: 'ADVANCED MENTORSHIP',
      price: 199,
      label: 'POPULAR',
      features: [
        { name: 'Discord community', available: true },
        { name: 'Full course SMC', available: true },
        { name: 'Synthetic Trading Course', available: true },
        { name: 'Scalping Mastery', available: true },
        { name: 'Scalping Mastery PDF', available: true },
        { name: 'Weekly zoom calls', available: true },
        { name: 'HTF setups', available: true },
        { name: 'daily outlook', available: false },
        { name: 'Live Teaching', available: false },
        { name: 'Mentorship', available: true }
      ]
    },
    {
      title: 'PREMIUM MENTORSHIP',
      price: 349,
      label: 'BEST',
      features: [
        { name: 'Discord community', available: true },
        { name: 'Full course SMC', available: true },
        { name: 'Synthetic Trading Course', available: true },
        { name: 'Scalping Mastery', available: true },
        { name: 'Scalping Mastery PDF', available: true },
        { name: 'Weekly zoom calls', available: true },
        { name: 'HTF setups', available: true },
        { name: 'daily outlook', available: true },
        { name: 'Live Teaching', available: true },
        { name: 'Mentorship', available: true }
      ]
    }
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="App">
      {/* <Nav/> */}
      <AppBar position="static" sx={{ backgroundColor: '#000' }}> {/* Change the background color here */}
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img width='50' src={logo} alt="" />
          </Typography>
          <Box>
            <Button color="inherit">Home</Button>
            <Button onClick={handleClickOpen} color="inherit">Course</Button>
            <Button color="inherit">Contact Us</Button>
          </Box>
        </Toolbar>
      </AppBar>
      <div className="first-container">

        <div className="intro-video">
          <h1><span className="slogan0">THE MARKET DOESN’T PLAY FAIR,</span> <span className="slogan1">WHY SHOULD YOU?</span></h1>
          <video style={{ maxWidth: 1000, width: "100%" }} height="100%" controls poster={face} controlsList='nodownload'>
            <source src="" type="video/mp4" />
          </video>
        </div>
        <div className="sign-up">
          <a href="#section2">
            <Button
              type="submit"
              variant="outlined"
              sx={{
                marginTop: 2,
                borderColor: '#d4af37',
                color: '#d4af37',
                '&:hover': {
                  borderColor: '#d4af37',
                  backgroundColor: 'rgba(212, 175, 55, 0.1)',
                }
              }}
            >
              SIGN ME UP NOW
            </Button>
          </a>

          <a href="#section2">
            <CircleButton />
          </a>

        </div>

      </div>
      <div className="second-container">
        <div className="slogan">
          <img width='200' src={logo} alt="" />
          <ul>
            <li>Learn</li>
            <li>Trade</li>
            <li>Earn</li>
          </ul>
        </div>
        <h2>At <span>AstuceFx</span> Academy, we believe in giving you the keys to unlock your full trading potential. This course is your passport to profitability, so fasten the seatbelt and get ready to dive into:</h2>
        <div >
          <InfoBox
            icon={<img width={100} src={strategy} alt="Trading Setups" />}
            title="PROFITABLE STRATEGIES"
            description="Unlock the secret strategies of top traders. These proven techniques go beyond theory to transform your financial future."
          />
          <InfoBox
            icon={<img width={100} src={setup} alt="Trading Setups" />}
            title="TRADING SETUPS"
            description="Gain access to exclusive trade ideas. We deliver winning trades every day for all market conditions that will consistently boost your profits."
          />
          <InfoBox
            icon={<img width={100} src={call} alt="Trading Setups" />}
            title="WEEKLY ZOOM CALS"
            description="Get expert analysis on the market’s weekly bias, ask your questions live, and walk away with a clear, focused mind."
          />
          <InfoBox
            icon={<img width={100} src={discord} alt="Trading Setups" />}
            title="COMMUNITY"
            description="Join our exclusive trading community to gain knowledge, ask questions, and boost your confidence with like-minded individuals."
          />
        </div>

      </div>
      <div className="third-container">
        <h1 style={{ textAlign: "center", marginTop: 50 }}><span className="slogan2">BELIEVE THEM,</span> <span className="slogan3">NOT US...</span></h1>
        <div>
          <Container sx={{ marginTop: 4 }}>
            <Carousel
              showArrows={true}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={3000}
              width={345}
            >
              {reviews.map((review, index) => (
                <div key={index}>
                  <ReviewCard review={review} />
                </div>
              ))}
            </Carousel>
          </Container>
          <p style={{ color: "#d4aa4f", textAlign: "center", fontSize: 20 }}>AND COUNTLESS MORE…</p>
        </div>
        <CircleButton />
      </div>
      <div className="third-container">
        <h1 style={{ textAlign: "center", marginTop: 50 }}><span className="slogan2">YES, I WANT TO GO,</span> <span className="slogan3">FROM ZERO TO FUNDED</span></h1>
        <CircleButton />
      </div>
      {/* mentorship packages */}
      <div id="section2" className="forth-container">

        {courses.map((course, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CourseBox course={course} />
          </Grid>
        ))}
      </div>
      <PaymentAndSocials />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Download AstuceFx Application</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose your platform to download the AstuceFx application and access the course.
          </DialogContentText>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
            <IconButton aria-label="Windows" component="a" href="http://astucefx.net/files/AstuceFx.exe">
              <WindowIcon fontSize="small" />
              <span style={{ marginTop: '5px', fontSize: '12px' }}>Windows</span>
            </IconButton>
            <IconButton aria-label="MacOS" component="a" href="http://astucefx.net/files/AstuceFx_Course_mac.zip">
              <AppleIcon fontSize="small" />
              <span style={{ marginTop: '5px', fontSize: '12px' }}>MacOS</span>
            </IconButton>
            <IconButton aria-label="Android" component="a" href="download-link-for-android">
              <AndroidIcon fontSize="small" />
              <span style={{ marginTop: '5px', fontSize: '12px' }}>Android</span>
            </IconButton>
            <IconButton aria-label="iOS" component="a" href="download-link-for-ios">
              <iOSIcon fontSize="small" />
              <span style={{ marginTop: '5px', fontSize: '12px' }}>iOS</span>
            </IconButton>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>);
}

export default Home;
